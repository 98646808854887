@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --light-text-color: rgb(150, 150, 150);
  --yellow-color: #eea521;
  --light-yellow: #ffc631;
  --bg-color: #222;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Archivo", sans-serif;
  font-style: normal;
}

button {
  all: unset;
}
