.app {
  background-color: var(--bg-color);
  color: #fff;
  overflow-x: hidden;
}

.loading_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  min-width: 100dvw;
  background-color: #fff;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--light-yellow);
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.invoice {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #000;
  font-size: 0.875rem;
}

.invoice > div {
  display: flex;
  justify-content: space-between;
}

.invoice > div > div {
  display: flex;
  gap: 3rem;
  justify-content: center;
}

.tooltip_text {
  font-size: 0.75rem;
}

.fw_600 {
  font-weight: 600;
}

.customers {
  background-color: #fff;
  padding: 1rem;
  color: #000;
}

.customer_nav {
  width: 90vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0.25rem 1rem;
  gap: 1rem;
}

.customers_table {
  padding: 1rem;
  width: 90vw;
  margin: auto;
}

.attednee {
  background-color: #fff;
}

.attendee_img1 {
  height: 15vh;
  width: 100%;
  object-fit: contain;
}

.details_flex > p {
  width: 35%;
}

.glass_login {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.7px);
  -webkit-backdrop-filter: blur(4.7px);
  border: none !important;
}

.glass_login label,
.glass_login h2 {
  color: #fff;
}

.ticket_activation {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}
