.top_line {
  height: 10px;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 99;
}

.top_line > div {
  width: 25%;
}

.red_line {
  background-color: #d52e36;
}

.blue_line {
  background-color: #1081a7;
}

.green_line {
  background-color: #1d994c;
}

.yellow_line {
  background-color: #eea521;
}

.banner {
  height: 30vh;
  padding: 2rem;
  margin: auto;
  text-align: center;
  display: flex;
}

.dj {
  height: 85vh;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  margin-bottom: 2rem;
  /* background: url("../assets/dj.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.dj > img,
.dj > video {
  height: 85vh;
  max-width: 100vw;
  object-fit: cover;
  overflow: hidden;
  object-position: top;
}

.media {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, transform 0.5s ease;
  overflow: hidden;
  background-color: var(--bg-color);
}

.visible {
  opacity: 1;
  transform: scale(1);
}

.hidden {
  opacity: 0;
  transform: scale(0.95);
  pointer-events: none; /* Prevent the hidden media from interfering with mouse events */
}

.section {
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 75%;
  justify-content: center;
  margin: 0 auto;
  background-color: var(--bg-color);
}

.section > div {
  width: 30dvw;
}

.section > div > div {
  margin-bottom: 1.5rem;
}

.section_img {
  max-height: 60vh;
}

.primary_heading {
  font-size: 2.25rem;
  font-weight: 700;
}

.secondary_heading {
  font-size: 1.875rem;
  font-weight: 600;
}

.yellow_text {
  color: var(--light-yellow);
}

.light_text {
  color: var(--light-text-color);
  letter-spacing: 0.5px;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.primary_btn > button {
  cursor: pointer;
  padding: 0.75rem 0.75rem;
  background-color: var(--yellow-color);
  font-size: 0.875rem;
  transition: all 0.3s ease;
}

.primary_btn > button:hover {
  opacity: 0.9;
}

.primary_btn > button:active {
  scale: 0.95;
}

.yellow_vet_line {
  border-top: 1px solid var(--yellow-color);
}

.location_cont {
  background: url("../assets/partyhall1.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 4rem 2rem;
}

.location_glass_cont {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.7px);
  -webkit-backdrop-filter: blur(4.7px);
  padding: 2rem;
}

.location_img > img {
  height: 35vh;
}

.yellow_cont {
  background-color: var(--light-yellow);
  padding: 0;
  margin: 0;
}

.spot {
  background-color: var(--light-yellow);
  color: #000 !important;
}

.spot_cont {
  width: 75%;
  margin: 0 auto;
  padding: 4rem 0 4rem;
}

.spot_cont > h2 {
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.spot_cont > p {
  color: inherit;
  font-weight: 550;
}

.apply {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 0;
  clip-path: polygon(0 20%, 50% 0, 100% 20%, 100% 100%, 0 100%);
  background-color: var(--bg-color) !important;
}

.sponsors {
  border-top: 1px solid var(--yellow-color) !important;
}

.sponsors_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 6rem 0 3rem;
}

.sponsors_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
}

.sponsors_list img {
  width: 150px;
}

.payment_main {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100dvh - 4px);
  /* background: url("../assets/payment_bg.png"); */
}

.payment_cont {
  height: 100%;
  overflow: hidden;
  max-width: 100dvw;
  width: 100vw;
  position: relative;
  object-fit: fill;
  /* z-index: -1; */
  transform: translate(-50%, -50%);
  background-size: cover;
  filter: brightness(0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

.header_img {
  position: relative;
  top: 5vh;
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.header_img img {
  height: 4rem;
}

.footer_img {
  position: fixed;
  bottom: 2.5vh;
  z-index: 111;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.footer_img > a > img {
  height: 2.75rem;
}

.footer_contact {
  font-size: 0.875rem;
}

.footer_contact > p:first-child {
  margin-bottom: 0.5rem;
}

.footer_contact > p > a {
  text-decoration: none;
  color: #fff;
}

.payment {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 11111;
  min-height: 80vh;
  padding: 1rem;
}

.payment_section_img > img {
  height: 50vh;
  object-fit: cover;
}

.payment_card {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1.25rem;
  max-width: 600px;
  width: 100%;
}

.contact_info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact_info > h2 {
  text-align: center;
  color: #000;
}

.input {
  /* width: 380px; */
}

.error_text {
  padding-top: 0.25rem;
  font-size: 0.75rem;
  color: red;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.success_cont {
  background-color: var(--bg-color);
}

.success_img > img {
  height: 15rem;
}

.ticket_type {
  display: flex;
  gap: 1rem;
}

.ticket_type_checkbox {
  /* border: 1px solid; */
  padding: 1rem 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.35);
}

.ticket_type_img > img {
  width: 100%;
  cursor: pointer;
}

.ticket_type_img > b {
  font-size: 0.8rem;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .section {
    width: 100%;
  }

  .section > div {
    width: 60dvw;
  }

  .light_text {
    font-size: 1rem;
  }

  .primary_heading {
    font-size: 1.75rem;
  }

  .secondary_heading {
    font-size: 1.5rem;
  }

  .section_img {
    width: 100%;
    max-height: auto;
  }

  .payment {
    flex-direction: column;
  }

  .payment_section_img > img {
    object-fit: cover;
  }

  .payment_card {
    width: 100%;
  }

  .input {
    width: 100%;
  }

  .sponsors_list > img {
    width: 15%;
  }

  .location_glass_cont {
    padding: 1rem;
  }

  .location_img > img {
    height: 25vh;
  }
}

@media screen and (max-width: 767px) {
  .section {
    width: 100%;
    flex-direction: column;
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .section_img {
    max-height: 30vh;
  }

  .section > div {
    width: 100%;
    text-align: center;
  }

  .section2 {
    flex-direction: column-reverse;
  }

  .light_text {
    font-size: 0.75rem;
    line-height: 1.1rem;
  }

  .primary_heading {
    font-size: 1.325rem;
  }

  .secondary_heading {
    font-size: 1.25rem;
  }

  .payment {
    flex-direction: column;
    width: 100%;
  }

  .payment_section_img > img {
    width: 100%;
    object-fit: cover;
  }

  .payment_card {
    width: 100%;
    padding: 0.75rem;
  }

  .contact_info {
    gap: 0.5rem;
  }

  .input {
    width: 100%;
  }

  .sponsors_list {
    gap: 1rem;
  }

  .sponsors_list > img {
    width: 15%;
  }

  .header_img img {
    height: 2.75rem;
  }

  .footer_img > img {
    height: 4rem;
  }

  .location_cont {
    flex-direction: column-reverse;
  }

  .location_img > img {
    height: 20vh;
  }

  .location_glass_cont {
    padding: 1rem;
  }

  .dj {
    height: 50vh;
  }

  .dj > img,
  .dj > video {
    height: 50vh;
  }
}
